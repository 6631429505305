import { ItemsProps } from './listItem.models';
import { Body, Icon, ItemRow, ContentRow, Title, Content } from './listItem.style';

/** CARDADDON DOCS
 *
 * @description styled-components for rendering CardAddOn present on Figma documentation
 * - items - recive an array of items that you want to span;
 * - icon - recive an array of icon for every items spanned the icon wil be spanned on the left;
 * - direction - need improvment, you can use this for span in column or in a row;
 * @author umbe
 * @param props
 */

const ListItems = (props: ItemsProps) => {
  const { items } = props;
  if (items === undefined || items.length === 0) {
    return null;
  }
  return (
    <ContentRow>
      {items.map((item) => (
        <ItemRow key={Math.random().toString()}>
          {item.icon && <Icon>{item.icon}</Icon>}
          <Content>
            {item.title && <Title>{item.title}</Title>}
            {item.body && <Body>{item.body}</Body>}
          </Content>
        </ItemRow>
      ))}
    </ContentRow>
  );
};

export default ListItems;
