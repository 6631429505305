import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont, pxToCssFontSize } from '@vfit/shared/themes';
import { ICSSProps } from './techCard.models';

export const TechContainer = styled.div<ICSSProps>`
  display: flex;
  flex: 1;
  align-self: center;
  margin: 0 auto;

  @media (min-width: ${breakpoints.mobile}) {
    width: 327px;
    flex-direction: column;
    margin-bottom: 32px;
  }

  @media (min-width: ${breakpoints.tablet}) {
    width: 680px;
    margin-bottom: 48px;
    flex-direction: row-reverse;
  }

  @media (min-width: ${breakpoints.desktop}) {
    width: 1062px;
    margin-bottom: 48px;
    ${(props) => (props.reverse ? `flex-direction: row;` : `flex-direction: row-reverse;`)}
  }
`;

export const TechImage = styled.div<ICSSProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;

  @media (min-width: ${breakpoints.mobile}) {
    flex: none;
    height: 150px;
    border-radius: 20px 20px 0 0;
  }

  @media (min-width: ${breakpoints.tablet}) {
    flex: 1;
    height: unset;
    border-radius: 0 20px 20px 0;
  }

  @media (min-width: ${breakpoints.desktop}) {
    flex: 1;
    height: unset;
    ${(props) =>
      props.reverse ? `border-radius: 20px 0 0 20px;` : `border-radius: 0 20px 20px 0;`}
  }
`;

export const TechCardDesc = styled.div<ICSSProps>`
  border: 1px solid ${colors.$bebebe};
  flex: 1;

  @media (min-width: ${breakpoints.mobile}) {
    border-radius: 0 0 20px 20px;
  }

  @media (min-width: ${breakpoints.tablet}) {
    border-radius: 20px 0 0 20px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    ${(props) =>
      !props.reverse ? `border-radius: 20px 0 0 20px;` : `border-radius: 0 20px 20px 0;`}
  }
`;

export const TechCardDescContainer = styled.div`
  margin: 36px 24px;

  @media (min-width: ${breakpoints.desktop}) {
    margin: 64px;
  }
`;

export const TechTitle = styled.div`
  align-items: center;
  display: flex;

  div {
    font-family: ${fonts.exbold};
    color: ${colors.$262626};
    font-weight: 400;
    margin: 0 10px 24px 0;
    ${pxToCssFont(30, 38)}

    img {
      width: 24px;
    }

    @media (min-width: ${breakpoints.desktop}) {
      ${pxToCssFont(36, 45)}
    }
  }
`;

export const TechDesc = styled.div`
  div {
    text-align: left;
    margin: 0 0 30px;
    font-family: ${fonts.regular};
    color: ${colors.$262626};
    font-weight: 400;
    ${pxToCssFont(18, 24)}

    img {
      display: none;
    }
  }
`;

export const ExtraItemListTitle = styled.div`
  margin-bottom: 35px;
  font-family: ${fonts.exbold};
  ${pxToCssFont(20, 30)}
`;

export const TechIcons = styled.div`
  margin-bottom: 35px;
`;

export const ExtraItemList = styled.div`
  border-spacing: 10px;
  display: flex;
  gap: 16px;
`;

export const Title = styled.span`
  align-self: center;
  font-weight: 400;
  font-family: ${fonts.exbold};

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(16, 22)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(20, 30)}
  }
`;

export const ItemRow = styled.div`
  font-family: ${fonts.regular};
  font-style: normal;
  width: stretch;
  align-items: center;
  display: flex;
  flex-direction: column;
  ${pxToCssFontSize(16)}

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(18, 24)}
  }
`;

export const BodyContainer = styled.div`
  display: flex;
  gap: 32px;
`;

export const BodyGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Body = styled.span`
  align-self: center;
  text-align: center;
  font-weight: 400;
  font-family: ${fonts.regular};

  @media (min-width: ${breakpoints.mobile}) {
    ${pxToCssFont(12, 16)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(16, 22)}
  }
`;
