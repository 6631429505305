import styled from 'styled-components';
import {
  breakpoints,
  colors,
  fonts,
  shadows,
  pxToCssFont,
  pxToCssFontSize,
} from '@vfit/shared/themes';

export const ContentRow = styled.div`
  border-spacing: 10px;
  display: flex;
  gap: 16px;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.$ffffff};
  box-shadow: ${shadows.card_overlay};
  border-radius: 6px;
  color: #333;
  font-style: normal;
`;

export const ItemRow = styled.div`
  font-family: ${fonts.regular};
  font-style: normal;
  width: stretch;
  align-items: center;
  display: flex;
  flex-direction: column;
  ${pxToCssFontSize(16)}

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(18, 24)}
  }
`;

export const Title = styled.span`
  align-self: center;
  font-weight: 400;
  font-family: ${fonts.exbold};

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(16, 22)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(20, 30)}
  }
`;

export const Body = styled.span`
  align-self: center;
  text-align: center;
  font-weight: 400;
  font-family: ${fonts.regular};

  @media (min-width: ${breakpoints.mobile}) {
    ${pxToCssFont(12, 16)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(16, 22)}
  }
`;

export const Icon = styled.div`
  align-self: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;
