import { Download, Latenza, Upload } from '@vfit/shared-icons';
import { ButtonSlide, CustomText, ImageAtoms, ListItems } from '@vfit/shared/atoms';
import {
  TechCardDescContainer,
  TechContainer,
  TechImage,
  TechCardDesc,
  TechTitle,
  TechDesc,
  TechIcons,
  ExtraItemList,
  ExtraItemListTitle,
  ItemRow,
  Body,
  BodyGroup,
  BodyContainer,
} from './techCard.style';
import { ITechCard } from './techCard.models';

const TechCard = ({
  techCard,
  download,
  upload,
  latency,
  even,
  onDetail,
  isMobile,
  extraItems,
  downloadSpeed,
}: ITechCard) => {
  const { title, image, imageMobile, action, description, showInfoLine } = techCard;

  const iconsList = [
    { icon: <Download />, title: 'Download', body: download },
    { icon: <Upload />, title: 'Upload', body: upload },
    { icon: <Latenza />, title: 'Latency', body: latency },
  ];

  const extraItemsList = () => (
    <TechIcons>
      <ExtraItemList>
        <ItemRow>
          <Download />
          <ExtraItemListTitle>Velocità di Download</ExtraItemListTitle>
          <BodyContainer>
            {downloadSpeed?.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <BodyGroup key={index}>
                <Body>{item.value}</Body>
                <Body>{item.unit}</Body>
                <Body>{item.speed}</Body>
              </BodyGroup>
            ))}
          </BodyContainer>
        </ItemRow>
        <ItemRow>
          <Latenza />
          <ExtraItemListTitle>Latenza</ExtraItemListTitle>
          <Body>{latency}</Body>
        </ItemRow>
      </ExtraItemList>
    </TechIcons>
  );

  return (
    <TechContainer reverse={even}>
      <TechImage reverse={even}>
        <ImageAtoms
          nameCard="techCard"
          image={image}
          imageMobile={imageMobile}
          isMobile={isMobile}
        />
      </TechImage>
      <TechCardDesc reverse={even}>
        <TechCardDescContainer>
          <TechTitle>{title && <CustomText text={title} />}</TechTitle>
          <TechDesc>{description && <CustomText text={description} />}</TechDesc>
          {extraItems && extraItemsList()}
          {showInfoLine && (
            <TechIcons>
              <ListItems items={iconsList} />
            </TechIcons>
          )}
          <div>
            <ButtonSlide
              borderColor="#262626"
              hoverColor="#262626"
              clickColor="#7e7e7e"
              hoverTextColor="#fff"
              clickTextColor="#fff"
              onClick={onDetail}
              label={action?.title || ''}
              name={`action_tech_${action?.type?.toString() || ''}`}
            />
          </div>
        </TechCardDescContainer>
      </TechCardDesc>
    </TechContainer>
  );
};

export default TechCard;

