export const Latenza = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.73294 0C5.46132 0 5.20076 0.101259 5.00862 0.281118C4.81648 0.461219 4.70871 0.705446 4.70871 0.960043V20.5365L1.88164 17.5985C1.70493 17.3924 1.44541 17.2636 1.16404 17.2427C0.882652 17.2217 0.604601 17.3104 0.395478 17.4881C0.186361 17.6656 0.0644451 17.9161 0.0587823 18.1805C0.0533808 18.445 0.164242 18.6999 0.365641 18.885L4.97492 23.6855C5.16912 23.8858 5.44434 23.9999 5.73295 23.9999C6.02157 23.9999 6.29677 23.8858 6.49098 23.6855L11.1003 18.885C11.3017 18.6999 11.4125 18.445 11.4071 18.1805C11.4015 17.9161 11.2795 17.6656 11.0704 17.4881C10.8613 17.3104 10.5833 17.2217 10.3019 17.2427C10.0205 17.2636 9.76094 17.3924 9.58426 17.5985L6.75719 20.5365V0.960043C6.75719 0.705446 6.64942 0.461219 6.45728 0.281118C6.26514 0.101259 6.00458 0 5.73296 0L5.73294 0Z"
        fill="#333"
      />
      <path
        d="M19.9331 23.9998C20.2047 23.9998 20.4653 23.8985 20.6574 23.7186C20.8495 23.5385 20.9573 23.2943 20.9573 23.0397L20.9573 3.46321L23.7844 6.40123C23.9611 6.60737 24.2206 6.73611 24.502 6.75709C24.7834 6.77806 25.0614 6.68934 25.2705 6.51165C25.4797 6.3342 25.6016 6.0837 25.6072 5.81921C25.6126 5.55472 25.5018 5.29988 25.3004 5.11473L20.6911 0.314296C20.4969 0.113946 20.2217 -9.58088e-05 19.9331 -9.58324e-05C19.6445 -9.58561e-05 19.3693 0.113946 19.175 0.314296L14.5658 5.11473C14.3644 5.29989 14.2535 5.55473 14.2589 5.81921C14.2646 6.08369 14.3865 6.33419 14.5956 6.51165C14.8047 6.68934 15.0828 6.77806 15.3642 6.75709C15.6455 6.73611 15.9051 6.60737 16.0818 6.40123L18.9088 3.46321L18.9088 23.0397C18.9088 23.2943 19.0166 23.5385 19.2087 23.7186C19.4009 23.8985 19.6614 23.9998 19.9331 23.9998L19.9331 23.9998Z"
        fill="#333"
      />
    </svg>
  );
};
